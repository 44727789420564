import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { NextPage, GetServerSideProps } from 'next';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Link as StyledLink } from '@makeship/core';

import {
  AuthContainer,
  AuthWrapper,
  AuthHeader,
  AuthMessages,
  AuthErrorMessage,
  AuthForm,
  AuthButton,
  AuthLinkContainer,
  AuthLinkText,
  MiddleLine,
  AuthLink,
} from '../components/Auth';
import Head from '../components/Head';
import { InputField, PasswordField } from '../components/Input';
import { withoutAuth } from '../components/withAuth';

import { useStore } from '../store';
import { login, addCustomer, logout } from '../store/store.actions';
import { getAccessToken, getCustomerUserError } from '../api/customer';
import { getCustomer } from '../api/storefront/customer';
import { validate } from '../utils/input-validator';

import config from '../../config.json';
import { S1, P2 } from '../components/Typography';
import { getPropsForPageLoad } from '../utils/pageLoad';

const { routes } = config;

const ResetLink = styled(StyledLink.Primary)`
  position: relative;
  display: inline-block;
  margin: 0 auto 8px -2px;
  top: -8px;
`;

const FeaturesWrapper = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const Feature = styled.li`
  margin-right: 8px;
  margin-bottom: 8px;
  position: relative;
  text-align: center;
  &:before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.primaryLight};
    vertical-align: middle;
    margin-right: 8px;
  }
`;

const AuthReason = styled(S1)`
  color: ${({ theme }) => theme.colors.error};
  text-align: center;
  margin-bottom: 16px;
`;

const accountFeatures = ['Collect/Share Badges', 'Express Checkout', 'Order Tracking'];

export const renderAccountFeatures = () => (
  <FeaturesWrapper>
    {accountFeatures.map((feature) => (
      <Feature key={feature}>{feature}</Feature>
    ))}
  </FeaturesWrapper>
);

declare const window: any;

const LoginPage: NextPage = () => {
  const { dispatch } = useStore();
  const router = useRouter();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [pending, setPending] = useState(false);
  const [errors, setErrors] = useState<AuthError>({});

  const authReason = router.query?.authReason;

  useEffect(() => {
    if (Object.keys(errors).length) {
      window.scrollTo(0, 0);
    }
  }, [errors]);

  const onLogin = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setErrors({});

    const errors: AuthError = validate({ email, password });
    if (errors.email || errors.password) {
      setErrors(errors);
      return;
    }

    setPending(true);

    try {
      const data = await getAccessToken(email, password);

      if (data?.customerUserErrors.length !== 0) {
        setErrors({ form: getCustomerUserError(data?.customerUserErrors) });
        return;
      }

      if (!data?.customerAccessToken?.accessToken) {
        return;
      }

      const action = await login(data.customerAccessToken.accessToken, data.customerAccessToken.expiresAt);
      dispatch(action);

      const customer = await getCustomer(data.customerAccessToken.accessToken).then((res) => res.data);
      if (customer) {
        if (typeof window !== 'undefined' && window.TriplePixel) {
          window.TriplePixel('Contact', { email: customer.email });
        }
        dispatch(addCustomer(customer));
      }

      router.push(routes.account);
    } catch (err) {
      setErrors({ form: 'Error logging in, please try again' });
      dispatch(logout());
    } finally {
      setPending(false);
    }
  };

  return (
    <>
      <Head title="Login" url="/login" />
      <AuthContainer>
        <AuthWrapper>
          <AuthHeader>Sign in to your account</AuthHeader>

          {authReason && <AuthReason>{authReason}</AuthReason>}

          {renderAccountFeatures()}
          <AuthMessages hide={!errors.form}>
            {errors.form && <AuthErrorMessage>{errors.form}</AuthErrorMessage>}
          </AuthMessages>

          <AuthForm onSubmit={onLogin} noValidate>
            <InputField
              id="email"
              type="email"
              error={!!errors.form || !!errors.email}
              errorMessage={errors.email}
              value={email}
              label="Email"
              placeholder="example@email.com"
              onChange={(e) => setEmail(e.target.value)}
            />
            <PasswordField
              id="password"
              error={!!errors.form || !!errors.password}
              errorMessage={errors.password}
              value={password}
              label="Password"
              placeholder="●●●●●●●●"
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              showPasswordIcon
            />
            <Link href={routes.forgotPassword} passHref>
              <ResetLink>Forgot your password?</ResetLink>
            </Link>
            <AuthButton type="submit" disabled={pending}>
              Sign In
            </AuthButton>
          </AuthForm>
        </AuthWrapper>
        <AuthLinkContainer>
          <AuthLinkText>
            <MiddleLine>New to Makeship?</MiddleLine>
          </AuthLinkText>
          <Link href={routes.signup} passHref>
            <AuthLink>Create Account</AuthLink>
          </Link>
        </AuthLinkContainer>
      </AuthContainer>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = withoutAuth(async (ctx) => {
  const propsForPageLoad = await getPropsForPageLoad(ctx);

  return {
    props: {
      ...propsForPageLoad,
    },
  };
});

export default LoginPage;
