export const validate = ({
  email,
  password,
  passwordValid = true,
  confirmPassword,
  firstName,
  lastName,
  address,
  city,
  country,
  phoneNumber,
  username,
  trackingId,
  orderId,
}: AuthError): AuthError => {
  const errors: AuthError = {};
  if (!email?.trim()) {
    errors.email = 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(email)) {
    errors.email = 'Please include an @ in the email address';
  }

  const currentPassword = password?.trim();
  if (!passwordValid) {
    errors.password = "Password doesn't meet requirements";
  } else if (!currentPassword) {
    errors.password = 'Password is required';
  } else if (currentPassword.length < 6) {
    errors.password = 'Password should have at least 6 characters';
  }

  if (!confirmPassword) {
    errors.confirmPassword = 'Please type your password again';
  } else if (password !== confirmPassword) {
    errors.confirmPassword = 'Your passwords do not match';
  }

  if (!firstName?.trim()) {
    errors.firstName = 'First Name is required';
  }

  if (!lastName?.trim()) {
    errors.lastName = 'Last Name is required';
  }

  if (!address?.trim()) {
    errors.address = 'Address is required';
  }

  if (!city?.trim()) {
    errors.city = 'City is required';
  }

  if (!country?.trim()) {
    errors.country = 'Country is required';
  }

  const currentUsername = username?.trim();
  if (!currentUsername) {
    errors.username = 'Username is required';
  } else if (/\s/g.test(currentUsername)) {
    errors.username = "Usernames can't contain spaces";
  } else if (/\S+@\S+\.\S+/.test(currentUsername)) {
    errors.username = 'Username cannot be an email';
  }

  if (!phoneNumber?.trim()) {
    errors.phoneNumber = 'Phone number is required';
  }

  if (phoneNumber && !/^\+[1-9]\d{10,14}$/g.test(phoneNumber)) {
    errors.phoneNumber = 'Phone Number is not in a proper format';
  }

  if (!trackingId?.trim()) {
    errors.trackingId = 'Tracking id is required';
  }

  if (!orderId?.trim()) {
    errors.orderId = 'Order id is required';
  }

  return errors;
};
