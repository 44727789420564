import styled from 'styled-components';
import { utils, Button } from '@makeship/core';

import Container from '../Container';
import { P1, P2, H4 } from '../Typography';

export const AuthContainer = styled(Container)`
  padding: 24px 48px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    padding: 24px 16px;
  }
`;

export const AuthWrapper = styled.div`
  width: 100%;
  max-width: 668px;
  margin: 0 auto;
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.neutral1};
  box-shadow: 0px 0px 6px ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.light)};
  border-radius: 3px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}px) {
    box-shadow: none;
    padding: 0;
  }
`;

export const AuthHeader = styled(H4)`
  text-align: center;
  margin-bottom: 16px;
  text-transform: uppercase;
`;

export const AuthSubtitle = styled(P1)`
  text-align: center;
  margin-bottom: 8px;
  padding: 0 72px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    box-shadow: none;
    padding: 0;
  }
`;

export const AuthForm = styled.form``;

export const AuthButton = styled(Button.Primary)`
  width: 100%;
`;

export const AuthMessages = styled.div<{ hide: boolean }>`
  margin-bottom: 27px;
  text-align: center;
  display: ${({ hide }) => (hide ? 'none' : 'display')};
`;

export const AuthErrorMessage = styled(P2)`
  color: ${({ theme }) => theme.colors.error};
`;

export const AuthLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 604px;
  margin: 24px auto;
`;

export const AuthLinkText = styled(P2)`
  width: 100%;
  text-align: center;
  border-bottom: 2px solid ${({ theme }) => theme.colors.neutral7};
  line-height: 0.1em;
  margin: 10px 0 20px;
`;

export const MiddleLine = styled.span`
  background-color: ${({ theme }) => theme.colors.neutral1};
  padding: 0 10px;
`;

export const AuthLink = styled(Button.Secondary)`
  width: 100%;
`;
